<template>
  <tepmplate-block
    mod-class
    content-class="overflow-auto main-wrap pt-4"
    title-page="Обучение, новости, лента"
  >
    <vuescroll>
      <router-link :to="{ name: 'NewsCreate' }">Создать</router-link>

      <b-card
        v-for="(item, index) in items"
        :key="index"
        :img-src="item.preview.url"
        img-alt="Card image"
        img-left
        class="mb-3"
        :img-width="250"
      >
        <b-card-text>
          <div class="news-title">{{ item.title }}</div>
          <b-card-text v-if="item.tags && item.tags.length" class="mt-2">
            <span
              v-for="(tag, tagIndex) in item.tags"
              :key="tagIndex"
              class="mr-2 card-tag"
              >{{ tag }}</span
            >
          </b-card-text>
          <b-card-text class="small text-muted"
            >Обновлено: {{ moment(item.updatedAt).format("LLL") }}</b-card-text
          >
        </b-card-text>
      </b-card>
    </vuescroll>
  </tepmplate-block>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import vuescroll from "vuescroll";

export default {
  name: "NewsList",
  components: { TepmplateBlock, vuescroll },
  data() {
    return {
      items: []
    };
  },
  methods: {
    getNewsList() {
      const fakeNew = {
        title:
          "В Германии приют зарегистрировал своих животных в приложении для знакомств.",
        body: "lorem ipsum",
        tags: ["Гаджеты", "Путешествия", "Финансы", "Котики"],
        companyId: "",
        preview: {
          name: "",
          path: "",
          url:
            "https://i.natgeofe.com/n/f0dccaca-174b-48a5-b944-9bcddf913645/01-cat-questions-nationalgeographic_1228126.jpg"
        },
        id: "1",
        authorId: "2",
        createdAt: new Date(),
        updatedAt: new Date()
      };
      this.items = [].concat(...Array(12).fill(fakeNew));
    }
  },
  created() {
    this.getNewsList();
  }
};
</script>

<style scoped lang="scss">
.card {
  max-width: inherit;
  background-color: #c6e4eb;
}

.news-title {
  font-size: 22px;
}

.card-tag {
  color: white;
  padding: 2px 5px;
  background-color: #6a9ad7;
  border-radius: 3px;
  font-size: 14px;
}
</style>
